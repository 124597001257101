@tailwind base;
@tailwind components;
@tailwind utilities;

.ql-editor{
  @apply h-96 !important;
}
@font-face {
  font-family: "Space";
  src: local("Space"),
   url("./font/SpaceGrotesk-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
 }


body {
  margin: 0;
  font-family: "Space", BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.singlePost{
  line-height: 24px;
  }